import { Component } from 'react';

import { ARTISEE_ID_URL } from '../../config';

class LoginPopup extends Component {
  componentDidMount() {
    const redirectUri = encodeURI(`${window.location.origin}/callback/web/login/`);
    window.location.replace(`${ARTISEE_ID_URL}/login/select?redirect_uri=${redirectUri}&appYn=N&logoYn=Y`);
  }

  render() {
    return null;
  }
}

export default LoginPopup;
